



































import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import AppService from '@common-src/service3/app';
import { AppSourceType } from '@common-src/model/enum';
import { AppEntityModel } from '@common-src/entity-model/app-entity';
import { getPermissionApp, getPermissionAppV2 } from '@common-src/service3/common.service';

@Component
export default class AppSelectComponent extends BaseComponent {
    appList: Array<any> = null;
    created() {
        // AppService.getAllApp().then(res => {
        //     this.appList = res;
        // });
        getPermissionAppV2().then(res => {
            this.appList = res;
        });
    }
    getOpenUrl(appModel) {
        if (_.get(appModel, 'type') === AppSourceType.THIRD) {
            const url = _.get(appModel, 'webUrl');
            if (url) {
                return _.includes(url, 'http') ? url : `http://${url}`;
            }
            return url;
        }
        return `/web/${appModel.identifier}/`;
    }
    async selectAppClick(appModel: AppEntityModel) {
        const openUrl = this.getOpenUrl(appModel);
        if (openUrl) {
            window.open(openUrl, '_blank');
        } else {
            this.showMessageWarning('站点配置错误，请联系管理员');
        }
    }
}

